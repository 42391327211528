import React from "react";
import profile from "../assets/profile-pic.png";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-center md:justify-between md:p-10 md:mx-20 md:mt-10 ">
      <div className="text-center  md:text-left space-y-4">
        <h1 className="text-4xl font-bold">
          Hi, <br /> I&apos;m{" "}
          <span className="text-red-500">Akash Kshirsagar</span>
        </h1>
        <h2 className="text-3xl">Full Stack Developer</h2>
        <button
          onClick={() => {
            navigate("/contact");
          }}
          className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600"
        >
          Contact
        </button>
      </div>
      <div className="w-60 h-60 md:w-80 md:h-80 border-4 border-white rounded-full overflow-hidden mb-20">
        <img src={profile} alt="Safet" className="object-cover w-full h-full" />
      </div>
    </div>
  );
}

export default Landing;
