import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast, { showErrorToast } from "./utils/Toast";
import axios from "axios";

function Contact() {
  const navigate = useNavigate();

  const [isMessageSent, setIsMessageSent] = useState(false);

  const messageSubmit = (e) => {
    e.preventDefault();
    if (
      e.target["name"].value.length === 0 ||
      e.target["email"].value.length === 0 ||
      e.target["message"].value.length === 0 ||
      e.target["phone"].value.length === 0
    ) {
      showErrorToast("Please fill all details");
      return;
    }
    let contactData = {
      name: e.target["name"].value,
      email: e.target["email"].value,
      message: e.target["message"].value,
      phone: e.target["phone"].value,
    };
    axios
      .post("http://api.akashkshirsagar.com/contacts", { ...contactData })
      .then((res) => {
        console.log(res);
        setIsMessageSent(true);
      })
      .catch((err) => {
        console.log(err);
        setIsMessageSent(true);
      });
  };

  return (
    <div className="min-h-screen bg-black text-white font-sans flex flex-col items-center py-10 px-5">
      {/* Heading */}
      {!isMessageSent && (
        <div>
          <h1 className="text-4xl font-bold mb-5">Contact Me</h1>
          <p className="text-gray-400 mb-10 text-center max-w-md">
            I would love to hear from you! Whether it’s a project, job
            opportunity, or just a chat, feel free to reach out.
          </p>

          {/* Contact Form */}

          <form
            className="w-full max-w-lg bg-gray-800 rounded-lg p-6 space-y-6"
            onSubmit={(e) => messageSubmit(e)}
          >
            <div>
              <label htmlFor="name" className="block text-sm font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                key={"name"}
                placeholder="Enter your name"
                className="w-full p-3 rounded-md bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                key={"email"}
                placeholder="Enter your email"
                className="w-full p-3 rounded-md bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-2">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                key={"phone"}
                placeholder="Enter your phone"
                className="w-full p-3 rounded-md bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="5"
                key={"message"}
                placeholder="Write your message..."
                className="w-full p-3 rounded-md bg-gray-900 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition"
            >
              Send Message
            </button>
          </form>
        </div>
      )}

      {isMessageSent && (
        <div>
          <h1 className="text-4xl font-bold mb-5 text-center">Thank You! 🎉</h1>
          <p className="text-gray-400 text-lg mb-10 text-center max-w-md">
            I appreciate you reaching out. I will get back to you as soon as
            possible. In the meantime, feel free to explore my social media
            links or portfolio.
          </p>

          {/* Back to Home Button */}
          <button
            className="bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition"
            onClick={() => {
              navigate("/home");
            }}
          >
            Back to Home
          </button>
        </div>
      )}
    </div>
  );
}

export default Contact;
