import React from "react";
import toast, { Toaster } from "react-hot-toast";

export function showErrorToast(message) {
  toast.error(message, {
    style: {
      background: "#1f2937", // Dark gray background
      color: "#fff", // White text
      border: "1px solid #ef4444", // Red border
    },
    iconTheme: {
      primary: "#ef4444", // Red icon
      secondary: "#fff", // White background
    },
  });
}

function Toast() {
  return <Toaster position="top-right" reverseOrder={false} />;
}

export default Toast;
