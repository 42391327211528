import React, { createContext, useState, useEffect } from "react";
import Title from "./components/Title";
import Login from "./components/Login";
import Contact from "./components/Contact";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Landing from "./components/Landing";
import ContactList from "./components/ContactList";
import Toast from "./components/utils/Toast";
import Projects from "./components/Projects";

function App() {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("user", JSON.stringify(user));
  }, [token, user]);

  return (
    <div className="App min-h-screen min-w-full bg-black text-white font-sans">
      <Toast />
      <BrowserRouter>
        <Title token={token} user={user} />
        {!token ? (
          <Routes>
            <Route path="*" element={<Navigate to={`/`} />} />
            <Route path="/" element={<Landing />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/login"
              element={<Login setUser={setUser} setToken={setToken} />}
            />
            <Route path="/projects" element={<Projects />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="*" element={<Navigate to={`/`} />} />
            <Route
              path="/contact-list"
              element={<ContactList user={user} token={token} />}
            />
            <Route path="/" element={<Landing />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
