import axios from "axios";
import React, { useEffect, useState } from "react";

function Projects() {
  const [projectsList, setProjectsList] = useState([]);

  const getProjects = () => {
    axios
      .get("http://api.akashkshirsagar.com/projects")
      .then((res) => {
        console.log(res.data.results);
        setProjectsList(() => [...res.data.results]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white font-sans py-10 px-5">
      <h1 className="text-4xl font-bold text-center mb-10">My Projects</h1>

      {/* Project Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
        {projectsList.map((project, index) => (
          <a href={project.link} target="_blank">
            <div
              key={index}
              className="bg-gray-800 border-2 border-gray-400 rounded-lg hover:border-red-500"
            >
              <img
                src={
                  "https://akash-kshirsagar.s3.ap-south-1.amazonaws.com/project-screenshots/" +
                  project.img
                }
                alt={project.name}
                className="w-full h-48 object-cover"
              />

              <div className="p-5 ">
                <h3 className="text-2xl font-semibold mb-3">{project.name}</h3>
                <p className="text-gray-400">{project.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Projects;
